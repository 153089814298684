.side-gutter {
    margin-left: 1.2rem !important;
    margin-right: 1.2rem !important;
}
.side-padding {
    padding-left: 1.2rem !important;
    padding-right: 1.2rem !important;
    box-sizing: border-box !important;
}
.side-text-padding {
    padding-left: 1.2rem !important;
    padding-right: 1.2rem !important;
    box-sizing: border-box !important;
}
.katex-display {
    overflow-x: auto;
    overflow-y: hidden;
}
.no-scroll {
    overflow: hidden;
    position: fixed;
}

.button {
    padding: 0.5em 0.6em;
    background-color: #FFF;
    text-decoration: none;
    border-radius: 0.3rem;
    transition:
        transform 0.1s cubic-bezier(0.25,0.8,0.25,1),
        box-shadow 0.1s cubic-bezier(0.25,0.8,0.25,1);
    box-shadow:
        0 0.15rem 0.3rem rgba(0,0,0,0.16),
        0 0.15rem 0.3rem rgba(0,0,0,0.23);

    &:hover {
        box-shadow:
            0 0.05rem 0.15rem rgba(0,0,0,0.12),
            0 0.05rem 0.1rem rgba(0,0,0,0.24);
        transform: scale(0.97);
    }

    &:active {
        transform: scale(1);
    }
}
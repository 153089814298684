header.hero {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 20rem;

    // Draw a semi-transparent background to overlay on the image
    &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 1em;
        left: 0;
        z-index: 0;
        content: "";

        background-image: linear-gradient($heroTransparency, $heroTransparency);
        border-radius: $cardBorderRadius;
    }

    // Background
    img {
        position: relative;
        height: inherit;
        max-height: initial;
        max-width: $cardMaxWidth;

        object-fit: $heroBackgroundObjectFit;
        object-position: $heroBackgroundObjectPosition;

        border-radius: $cardBorderRadius;
        box-shadow:
            0 0.5rem 1rem 0 rgba(0,0,0,0.19),
            0 0.3rem 0.3rem -0.1rem rgba(0,0,0,0.23);
    }

    div.text {
        text-align: center;
        position: absolute;
        z-index: 5;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -55%);
        color: $heroTextColor;

        // Headers
        h1, h2, h3, h4, h5, h6 {
            font-family: $heroHeaderFontFamily;
            font-weight: $heroHeaderFontWeight;
        }

        // Subtext
        p {

        }
    }
}
@media screen and (pointer: coarse) {
    .card {
        &:hover {
            transform: scale(0.95);
            box-shadow: 0 0.15rem 0.3rem 0 rgba(0, 0, 0, 0.16), 0 0.15rem 0.3rem -0.04rem rgba(0, 0, 0, 0.23);
        }
    }
}
@media not screen and (pointer: coarse) {
    .card {
        &:hover {
            transform: scale(0.99);
            box-shadow: 0 0.15rem 0.3rem 0 rgba(0, 0, 0, 0.16), 0 0.15rem 0.3rem -0.04rem rgba(0, 0, 0, 0.23);
        }

        &:active {
            transform: scale(1);
        }
    }
}
/* Medium devices (tablets, ~641px and up) */
@media only screen and (min-width: 40.063em) {
    body {
        font-size: 1.125rem;
    }
    .nav-bar {
        padding: 0.8em 0;
    }
    .list-header-title {
        font-weight: normal;
        font-size: 4.2em;
    }
    .card {
        border-radius: 0.2rem;
        /* margin: 2.5em 0; */
    }
    .blog-card {
        flex-direction: row;
        align-items: stretch;
    }
    .card-img {
        border-radius: 0.2rem 0 0 0.2rem;
        margin: 0;
        max-height: unset;
        height: 100%;
        width: 15em;
    }
    .card-img-overlay {
        border-radius: 0.2rem 0 0 0;
    }
    .card-body {
        padding: 1.5em 1.3em;
    }
    .card-title {
        font-size: 1.27em;
    }
    .card-text {
        font-size: 0.95em;
        margin: 1.2em 0;
    }
    .card-subtext {
        font-size: 0.7em;
    }
    .content {
        border-radius: 0.2rem;
    }
    .post {
        margin: 1em 1em 2em 1em;
    }
    .post-title {
        font-size: 2.5em;
    }
    .button {
        border-radius: 0.2rem;
    }
    code {
        border-radius: 0.2rem;
    }
}
.card-container {
    max-width: $cardMaxWidth;
}
.card {
    display: block;
    margin: 2.2rem 0;
    box-sizing: border-box;
    background-color: white;
    text-decoration: none;
    border-radius: 0.3rem;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition:
        transform $cardHoverAnimationDuration $cardHoverAnimation,
        box-shadow $cardHoverAnimationDuration $cardHoverAnimation;
    box-shadow:
        0 0.5rem 1rem 0 rgba(0,0,0,0.19),
        0 0.3rem 0.3rem -0.1rem rgba(0,0,0,0.23);
}
.card-img-container {
    position: relative;
}
.card-img {
    border-radius: 0.3rem 0.3rem 0 0;
    margin: 0 0 -0.28em 0;
    max-height: 10em;
    object-fit: cover;
}
.card-img-overlay {
    border-radius: 0.3rem 0.3rem 0 0;
    position: absolute;
    top: 0;
    font-size: 1.27em;
    text-align: center;
    padding: 1.18em 0 0.5em 0;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    color: white;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 5;
}
.card-body {
    padding: 1em;
}
.card-title {
    margin: 0;
    line-height: 1.2;
}
.card-text {
    margin: 1em 0;
    line-height: 1.5;
}
.card-subtext {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 0.8em;
}
.card-subtext > p {
    margin: 0;
}
.card-subtext > p + p {
    margin-left: 1em;
    padding-left: 1em;
    word-spacing: 0.5em;
    border-left: thin solid #7A7B7C;
}

.home-card {
    padding: 0.8em;
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    color: white;
    background-position: center center;
    object-fit: cover;
}
.blog-card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
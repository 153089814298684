// // // // // // // // // //
// COLOR PALETTE
// // // // // // // // // //
$deepSpaceSparkle: #3a606e;
$glitter: #e4f1fd;
$gunmetal: #23313e;
$romanSilver: #85939f;
$aurometalsaurus: #607b7d;


// // // // // // // // // //
// ELEMENTS AND COMPONENTS
// // // // // // // // // //
$bodyBackgroundBackdrop: url(../img/dots-glitter-tiny.png);
$bodyGradientAngle: -360deg;
$bodyGradientFromColor: $deepSpaceSparkle;
$bodyGradientToColor: $glitter;

$cardBorderRadius: 0.3rem;
$cardHoverAnimation: cubic-bezier(.08, 0, 0, .99);
$cardHoverAnimationDuration: 1.0s;
$cardMaxWidth: 50rem;

$contentMaxWidth: 75rem;

$fontFamily:
    "LatoLatinWeb", Avenir, HelveticaNeue-Light, "Helvetica Neue Light",
    "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$fontFamilyDropcase: "LatoLatinWebHeavyItalic";

$heroBackgroundObjectFit: cover;
$heroBackgroundObjectPosition: center top;
$heroBorderRadius: $cardBorderRadius;
$heroHeaderFontFamily:
    "Palanquin", sans-serif;
$heroHeaderFontWeight: 200;
$heroTextColor: $glitter;
$heroTransparency: rgba(0, 0, 0, 0.30);

$navTextFontFamily:
    "Palanquin", sans-serif;
$navTextFontWeight: 200;
$navMaxWidth: $contentMaxWidth;


// // // // // // // // // //
// HIGHLIGHT.JS
// // // // // // // // // //

/* Base16 Atelier Dune Light - Theme */
/* by Bram de Haan (http://atelierbram.github.io/syntax-highlighting/atelier-schemes/dune) */
/* Original Base16 color scheme by Chris Kempson (https://github.com/chriskempson/base16) */

$hljsComment: #7d7a68;
$hljsRed: #d73737;
$hljsOrange: #b65611;
$hljsGreen: #60ac39;
$hljsBlue: #6684e1;
$hljsPurple: #b854d4;
$hljsBackground: #fefbec;
$hljsDefault: #6e6b5e;
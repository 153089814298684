.hljs-comment,
.hljs-quote {
    color: $hljsComment;
}

.hljs-variable,
.hljs-template-variable,
.hljs-attribute,
.hljs-tag,
.hljs-name,
.hljs-regexp,
.hljs-link,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class {
    color: $hljsRed;
}

.hljs-number,
.hljs-meta,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-params {
    color: $hljsOrange;
}

.hljs-string,
.hljs-symbol,
.hljs-bullet {
    color: $hljsGreen;
}

.hljs-title,
.hljs-section {
    color: $hljsBlue;
}

.hljs-keyword,
.hljs-selector-tag {
    color: $hljsPurple;
}

.hljs {
    display: block;
    overflow-x: auto;
    background: $hljsBackground;
    color: $hljsDefault;
    padding: 0.5em;
}

.hljs-emphasis {
    font-style: italic;
}

.hljs-strong {
    font-weight: bold;
}
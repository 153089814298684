@import url('https://fonts.googleapis.com/css?family=Palanquin');

b,
strong {
    font-family: 'LatoLatinWebHeavy';
    font-weight: normal;
}
h1, h2, h3, h4, h5, h6 {
    margin: 1.5em 0 0.7em 0;
}
code {
    font-family: monospace;
    padding: .2em .5em;
    line-height: 1.5;
    border-radius: 0.3rem;
    background-color: #f3f3f3;
}
.muted-text {
    color: #7A7B7C;
}

.list-header {
    margin: 6em 0;
    text-align: center;
}

.list-header-image {
    display: block;
    border-radius: 0.3rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0,0,0,0.19), 0 0.3rem 0.3rem -0.1rem rgba(0,0,0,0.23);
}

.list-header-title {
    margin: 0.1em 0 0.2em 0;
    font-size: 2.2em;
    text-transform: uppercase;
}

.list-header-subtext {
    font-weight: normal;
    color: #7A7B7C;
    font-size: 1em;
    line-height: 1.6;
    margin: 0;
}

.nav-bar {
    max-width: $navMaxWidth;
    width: 100%;
    padding: 0.4em 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav-header {
    margin: 0;
}
.nav-text {
    font-family: $navTextFontFamily;
    font-weight: $navTextFontWeight;
    text-decoration: none;
    z-index: 105;
    font-size: 0.8em;
}
.end-nav {
    width: 100%;
    max-width: 49rem;
}

.pagination-nav {
    margin: 2em 0;
    width: 100%;
    max-width: 47rem;
}
.pagination-newer {
    float: left;
}
.pagination-older {
    float: right;
}
.hamburger-menu {
    display: block;
    position: relative;
    z-index: 105;
    -webkit-user-select: none;
    user-select: none;

    button {
        display: block;
        position: relative;
        width: 33px;
        height: 33px;
        padding: 0px;
        border: 0px;
        outline: none;
        background-color: transparent;
        z-index: 500; /* and place it over the hamburger */
        -webkit-touch-callout: none;
        cursor: pointer;

        span {
            display: block;
            width: 33px;
            height: 4px;
            position: relative;
            background-color: #3A3B3C;
            border-radius: 3px;
            transform-origin: center;
            transition:
                transform 0.3s cubic-bezier(0.77,0.2,0.05,1.0),
                background-color 0.3s cubic-bezier(0.77,0.2,0.05,1.0);

            &:first-of-type {
                margin-bottom: 5px;
            }
        }

    }
}

.hamburger-menu-open {
    button {
        span {
            background-color: white;
            transform: rotate(45deg) translate(3.2px , 3.2px);

            &:last-of-type {
                transform: rotate(-45deg) translate(3.2px , -3.2px);
            }
        }
    }

    .hamburger-menu-overlay {
        visibility: visible;
        opacity: 0.8;
    }
}

.hamburger-menu-overlay {
    display: block;
    position: fixed;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 100;
    text-align: center;
    visibility: hidden;
    overflow-y: auto;
    margin: 0;
    padding: 3.5em 0 0 0;
    background-color: #000;
    opacity: 0;
    transition: visibility 0.2s ease-out, opacity 0.2s ease-out;
}

.hamburger-menu-overlay-link {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 2em;
    line-height: 1.7;
    color: white;
}

.post {
    margin: 0 0 1em 0;
    line-height: 1.5;

    .header {
        margin: 0 0 1.5em 0;

        .title {
            font-size: 1.8em;
            line-height: 1.2;
            margin: 0 0 0.4em 0;
        }

        .subheader {
            display: inline-block;
            color: #7A7B7C;
            font-size: 0.8em;
            line-height: 0.2;

            .date {
                display: inline;
            }

            .tags {
                display: inline;
            }

            p::after {
                display: inline-block;
                content: "/";
                margin-left: 0.75rem;
                margin-right: 0.5rem;
            }

            p:last-child::after {
                content: "";
            }
        }
    }

    .figure {
        margin: 1.5em 0;
    }
}
body {
    font-family: $fontFamily;
    color: #3A3B3C;
    font-size: 1.1em;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    background-image:
        $bodyBackgroundBackdrop,
        linear-gradient(-360deg, $bodyGradientFromColor, $bodyGradientToColor);
    animation: background-gradient-rotation 300s ease-in-out infinite;
}
p {
    margin: 1em 0;
}
img {
    width: 100%;
    height: auto;
    margin: 1em 0;
}
blockquote {
    border-left: 0.3em solid #D1D1D1;
    margin: 1.5em 0.8em;
    padding: .5em 0.5em;
    font-style: italic;
}
blockquote p {
    display: inline;
}
ul,
ol {
    padding-left: 1.6em; 
}
li ul, li ol {
    padding-left: 1em;
}
li > p {
    margin: 0;
}
hr {
    border: 0;
    border-bottom: thin solid #D1D1D1;
    margin: 3em 0;
}
a {
    color: #3A3B3C;
}
table {
    color: #3A3B3C;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 1em 0;
}
td,
th {
    padding: 0.5em 1em;
    border: thin solid #D1D1D1
}
th {
    font-family: 'LatoLatinWebHeavy';
    font-weight: normal
}
tr:nth-child(even) td {
    background: #f3f3f3;
}
footer {
    padding: 1rem 1rem;
    /* margin: 1rem 0 0 0; */
    border-top: 1px dotted $glitter;
    text-align: center;
    color: $glitter;

    a {
        color: $glitter;
    }
}
